.App {
  text-align: center;
  margin: 0 25px;
}

.alert {
  box-shadow: var(--button-box-shadow, 0 0 0 rgba(0, 0, 0, 0));
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  margin: 20px;
}

.alert.info {
  background-color: #c3e5c3;
  color: black;
}

.alert.success {
  background-color: #4def4d;
  color: black;
}

.alert.error {
  background-color: #fc9191;
  color: black;
}